<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách cộng trừ khác'">
          <template v-slot:toolbar>
            <div class="row">
              <b-dropdown size="sm" id="dropdown-right" right variant="primary">
                <template slot="button-content">
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  <span class="font-weight-bolder">Thêm mới</span>
                </template>
                <b-dropdown-item @click="showModalAdd">
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Theo
                  nhân viên
                </b-dropdown-item>
                <b-dropdown-item @click="showModalAddByExcel">
                  <i style="font-size: 1rem" class="la la-file-excel-o"></i>Nhập
                  file excel
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <b-col cols="3" class="pl-0 pr-0">
                        <vue-autosuggest
                          style="padding-left: 0px; padding-right: 20px"
                          v-model="searchEmployeeName"
                          :suggestions="filteredOptionEmployeeSearch"
                          @selected="onSelectedEmployeeSearch"
                          :limit="10"
                          @input="onInputChangeEmployeeSearch"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Tìm theo nhân viên',
                          }"
                        >
                          <div
                            slot-scope="{ suggestion }"
                            style="display: flex; align-items: center"
                          >
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </vue-autosuggest>
                      </b-col>
                      <b-col cols="3" class="pl-0 pr-0">
                        <vue-autosuggest
                          style="padding-left: 0px; padding-right: 20px"
                          v-model="searchRewardProgramName"
                          :suggestions="filteredOptionRewardProgramSearch"
                          @selected="onSelectedRewardProgramSearch"
                          :limit="10"
                          @input="onInputChangeRewardProgramSearch"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Tìm theo chương trình',
                          }"
                        >
                          <div
                            slot-scope="{ suggestion }"
                            style="display: flex; align-items: center"
                          >
                            {{ suggestion.item.name }}
                          </div>
                        </vue-autosuggest>
                      </b-col>
                      <b-col cols="3" class="pl-0 pr-0">
                        <div class="search-col">
                          <b-input-group>
                            <date-picker
                              placeholder="Từ"
                              class="form-control form-control-sm"
                              :config="dpConfigs.date"
                              v-model="searchFromDay"
                            ></date-picker>
                          </b-input-group>
                          <span class="ml-1 mr-1"></span>
                          <b-input-group>
                            <date-picker
                              placeholder="Đến"
                              class="form-control form-control-sm"
                              :config="dpConfigs.date"
                              v-model="searchToDay"
                            ></date-picker>
                          </b-input-group>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <b-table
              :items="listItem"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
              </template>
              <template v-slot:cell(stt)="row">
                <div class="d-flex justify-content-center w-6">
                  <span v-text="row.item.stt"></span>
                </div>
              </template>
              <template v-slot:cell(amount)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.amount)"></span>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 10px" class="flaticon2-box-1"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('REWARD_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ convertPrice(totalRow) }}
                </p>
              </b-col>
              <div class="d-flex justify-content-end">
                <b-col>
                  <b-pagination-nav
                    v-show="numberOfPage >= 2"
                    class="custom-pagination"
                    :link-gen="linkGen"
                    :number-of-pages="numberOfPage"
                    use-router
                    @change="fetchData"
                    align="right"
                    first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                    prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
                    next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
                    last-class="page-item-last btn btn-icon btn-sm my-1"
                    page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                  ></b-pagination-nav>
                </b-col>
              </div>
            </b-row>
            <b-modal
              ref="add-modal"
              hide-footer
              title="Thêm mới cộng trừ khác hoa hồng cửa hàng theo nhân viên"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <b-form-group
                  id="input-group-3"
                  label="Chương trình:"
                  label-for="input-3"
                  class="col-md-6 pl-0"
                >
                  <vue-autosuggest
                    v-model="searchRewardProgram"
                    :suggestions="filteredOptionRewardProgram"
                    @selected="onSelectedRewardProgram"
                    :limit="10"
                    @input="onInputChangeRewardProgram"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Chọn chương trình',
                    }"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                </b-form-group>
                <b-form-group
                  id="input-group-3"
                  label="Nhân viên:"
                  label-for="input-3"
                  class="col-md-6 pl-0"
                >
                  <vue-autosuggest
                    v-model="searchEmployee"
                    :suggestions="filteredOptionEmployee"
                    @selected="onSelectedEmployee"
                    :limit="10"
                    @input="onInputChangeEmployee"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Chọn nhân viên',
                    }"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </vue-autosuggest>
                </b-form-group>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldEmployee"
                        :items="listDetailByEmp"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(date)="row">
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="row.item.date"
                          ></date-picker>
                        </template>
                        <template v-slot:cell(operatorType)="row">
                          <b-form-select
                            v-model="row.item.operatorType"
                            size="sm"
                          >
                            <b-form-select-option value="1">
                              +
                            </b-form-select-option>
                            <b-form-select-option value="2">
                              -
                            </b-form-select-option>
                          </b-form-select>
                        </template>
                        <template v-slot:cell(amountType)="row">
                          <b-form-select
                            v-model="row.item.amountType"
                            size="sm"
                          >
                            <b-form-select-option value="1">
                              $
                            </b-form-select-option>
                            <b-form-select-option value="2">
                              %
                            </b-form-select-option>
                          </b-form-select>
                        </template>
                        <template v-slot:cell(amount)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.amount"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(description)="row">
                          <b-form-textarea
                            id="input-description"
                            v-model="row.item.description"
                            placeholder="Nhập mô tả"
                            rows="3"
                          />
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlertItem(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('REWARD_INSERT')"
                  @click="saveEmpDetail()"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAdd"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-modal"
              hide-footer
              title="Cập nhật cộng trừ khác hoa hồng cửa hàng"
            >
              <v-form ref="form" lazy-validation>
                <b-form-group
                  id="input-group-3"
                  label="Chương trình:"
                  label-for="input-3"
                  class="col-md-6 pl-0"
                >
                  <vue-autosuggest
                    v-model="searchRewardProgram"
                    :suggestions="filteredOptionRewardProgram"
                    @selected="onSelectedRewardProgram"
                    :limit="10"
                    @input="onInputChangeRewardProgram"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Chọn chương trình',
                    }"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                </b-form-group>
                <b-form-group
                  id="input-group-start-date"
                  label-align-md="left"
                  required
                  label="Ngày"
                  class="col-md-6 pl-0"
                >
                  <date-picker
                    placeholder="Ngày"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="modelUpdate.date"
                  ></date-picker>
                </b-form-group>
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      label="Mã nhân viên:"
                      label-align-md="left"
                      class="required-control"
                    >
                      <b-form-input
                        size="sm"
                        v-model="modelUpdate.employeeCode"
                        placeholder="Nhập mã nhân viên"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Tên nhân viên:"
                      label-align-md="left"
                      class="required-control"
                    >
                      <b-form-input
                        size="sm"
                        v-model="modelUpdate.employeeName"
                        placeholder="Nhập tên nhân viên"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group
                          label="Toán tử:"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-select
                            v-model="modelUpdate.operatorType"
                            size="sm"
                          >
                            <b-form-select-option value="1">
                              +
                            </b-form-select-option>
                            <b-form-select-option value="2">
                              -
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="Loại:"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-select
                            v-model="modelUpdate.amountType"
                            size="sm"
                          >
                            <b-form-select-option value="1">
                              $
                            </b-form-select-option>
                            <b-form-select-option value="2">
                              %
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Giá trị:"
                      label-align-md="left"
                      class="required-control"
                    >
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="modelUpdate.amount"
                        v-mask="mask"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Mô tả">
                      <b-form-textarea
                        id="input-description"
                        v-model="modelUpdate.description"
                        placeholder="Nhập mô tả"
                        rows="3"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('REWARD_INSERT')"
                  @click="updateModel()"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdate()"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="add-by-excel-modal"
              hide-footer
              title="Nhập cộng trừ khác hoa hồng cửa hàng từ file excel"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <span>
                        <b>Nhập bằng file excel:</b>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="form-group">
                      <span>
                        <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file
                        excel đúng format, hoặc có thể tải ở
                        <a :href="urlExcel">đây</a>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <span> <b>Bước 2:</b> Tiến hành Import </span>
                    <div style="padding-top: 15px">
                      <b-form-file
                        placeholder="Hãy chọn một tập tin"
                        @change="selectFile"
                        v-model="file"
                        ref="file-input"
                        class="mb-2"
                      ></b-form-file>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldEmployee"
                        :items="listDetailByExcel"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(date)="row">
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="row.item.date"
                          ></date-picker>
                        </template>
                        <template v-slot:cell(operatorType)="row">
                          <b-form-select
                            v-model="row.item.operatorType"
                            size="sm"
                          >
                            <b-form-select-option value="1">
                              +
                            </b-form-select-option>
                            <b-form-select-option value="2">
                              -
                            </b-form-select-option>
                          </b-form-select>
                        </template>
                        <template v-slot:cell(amountType)="row">
                          <b-form-select
                            v-model="row.item.amountType"
                            size="sm"
                          >
                            <b-form-select-option value="1">
                              $
                            </b-form-select-option>
                            <b-form-select-option value="2">
                              %
                            </b-form-select-option>
                          </b-form-select>
                        </template>
                        <template v-slot:cell(amount)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.amount"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(description)="row">
                          <b-form-textarea
                            id="input-description"
                            v-model="row.item.description"
                            placeholder="Nhập mô tả"
                            rows="3"
                          />
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlertItem(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('REWARD_INSERT')"
                  @click="saveEmpDetailByExcel()"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddByExcel"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon {
  padding-top: 7px;
}

.icon:hover {
  background-color: #90c6fc;
}

.container-fluid {
  height: 100%;
  display: table;
  width: 100%;
  padding: 0;
}

.row-fluid {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.centering {
  float: none;
  margin: 0 auto;
}
</style>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { VueAutosuggest } from 'vue-autosuggest';
import { currencyMask, unMaskPrice, xoa_dau } from '../../../utils/common';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { v4 } from 'uuid';
import _ from 'lodash';
import xlsx from 'xlsx';
import { URL_IMPORT_REWARD_DETAIL } from '../../../utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      searchCode: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: { textAlign: 'center', fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'date',
          label: 'Ngày',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'employeeCode',
          label: 'Mã nhân viên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'operatorTypeName',
          label: '+ / -',
          sortable: false,
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'amountTypeName',
          label: '$ / %',
          sortable: false,
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'rewardProgramName',
          label: 'Chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      listItem: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: 1,
      count: 1,
      listCate: [],
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      numberOfPages: 1,
      filteredOptionRewardProgram: [],
      optionRewardProgram: [
        {
          data: [],
        },
      ],
      searchRewardProgram: '',
      selectedRewardProgram: null,
      fieldEmployee: [
        {
          key: 'date',
          label: 'Ngày',
        },
        {
          key: 'employeeName',
          label: 'Nhân viên',
        },
        {
          key: 'operatorType',
          label: '+/-',
        },
        {
          key: 'amountType',
          label: '$/%',
        },
        {
          key: 'amount',
          label: 'Giá trị',
        },
        {
          key: 'description',
          label: 'Mô tả',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      renderComponent: true,
      listDetailByEmp: [],
      filteredOptionEmployee: [],
      optionEmployee: [
        {
          data: [],
        },
      ],
      searchEmployee: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      modelUpdate: {
        id: null,
        employeeId: null,
        employeeCode: '',
        employeeName: '',
        operatorType: 1,
        amountType: 1,
        amount: 0,
        description: '',
      },
      listEmp: [],
      fieldEmp: [
        {
          key: 'employeeCode',
          label: 'Mã nhân viên',
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      mainModel: {
        id: null,
        date: moment().format('DD/MM/YYYY'),
        employeeId: null,
        employeeCode: '',
        employeeName: '',
        operatorType: 1,
        amountType: 1,
        amount: 0,
        description: '',
      },
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      urlExcel: URL_IMPORT_REWARD_DETAIL,
      optionEmployeeSearch: [
        {
          data: [],
        },
      ],
      searchEmployeeName: '',
      filteredOptionEmployeeSearch: [],
      selectedEmpId: null,
      filteredOptionRewardProgramSearch: [],
      optionRewardProgramSearch: [
        {
          data: [],
        },
      ],
      searchRewardProgramName: '',
      selectedRewardProgramId: null,
      searchFromDay: '',
      searchToDay: '',
      listDetailByExcel: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    VueAutosuggest,
  },
  created() {
    this.getListRewardProgram();
    this.fetchEmployee();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hoa hồng cừa hàng', route: 'reward-program' },
      { title: 'Danh sách cộng trừ khác' },
    ]);
    const fromDate = this.$route.query.fromDate;
    const toDate = this.$route.query.toDate;
    const employeeId = this.$route.query.employeeId;
    const employeeName = this.$route.query.employeeName;
    if (fromDate) {
      this.searchFromDay = fromDate;
    }
    if (toDate) {
      this.searchToDay = toDate;
    }
    if (employeeId) {
      this.selectedEmpId = employeeId;
    }
    if (employeeName) {
      this.searchEmployeeName = employeeName;
    }
    this.fetchData();
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'reward-accessory-detail',
      });
      this.fetchData();
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (this.searchEmployeeName.trim() === '') {
        this.selectedEmpId = null;
      }
      if (this.searchRewardProgramName.trim() === '') {
        this.selectedRewardProgramId = null;
      }
      const param = {
        type: 2,
        page: this.page,
        limit: 10,
        employeeId: this.selectedEmpId,
        rewardProgramId: this.selectedRewardProgramId,
        fromDate: this.searchFromDay
          ? moment(this.searchFromDay, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : null,
        toDate: this.searchToDay
          ? moment(this.searchToDay, 'DD/MM/YYYY').format('yyyy-MM-DD 23:59:59')
          : null,
      };
      const paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.setHeader();
      ApiService.query('reward-details', paramQuery)
        .then(async ({ data }) => {
          if (this.page === 1) {
            this.count = 1;
          } else {
            this.count = 1 + (this.page - 1) * 10;
          }
          this.totalRow = data.data.total_row;
          this.numberOfPage = data.data.total_page;
          this.listItem = [];
          data.data.data.forEach((element) => {
            let item = {
              ...element,
              stt: this.count,
            };
            this.listItem.push(item);
            this.count++;
          });
          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          throw new Error(err);
        });
    },
    splitAray(size, list) {
      return list.reduce((acc, curr, i, self) => {
        if (!(i % size)) {
          return [...acc, self.slice(i, i + size)];
        }
        return acc;
      }, []);
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('REWARD_VIEW')) {
        count++;
      }
      if (localData.checkPermission('REWARD_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    deleteItem: async function (item) {
      ApiService.setHeader();
      ApiService.delete(`reward-details/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa mức cộng trừ khác chương trình hoa hồng!',
        text: 'Bạn có chắc muốn xóa mức cộng trừ khác chương trình hoa hồng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    editItem: function (item) {
      this.rewardDetailId = item.id;
      this.showModalUpdate(item);
    },
    showModalAdd() {
      this.isNew = true;
      this.listDetailByEmp = [];
      this.$refs['add-modal'].show();
    },
    hideModalAdd() {
      this.$refs['add-modal'].hide();
    },
    showModalUpdate(item) {
      this.modelUpdate.id = item.id;
      this.modelUpdate.date = item.date;
      this.modelUpdate.employeeId = item.employeeId;
      this.modelUpdate.employeeCode = item.employeeCode;
      this.modelUpdate.employeeName = item.employeeName;
      this.modelUpdate.operatorType = item.operatorType;
      this.modelUpdate.amountType = item.amountType;
      this.modelUpdate.amount = item.amount;
      this.modelUpdate.description = item.description;
      this.selectedRewardProgram = item.rewardProgramId;
      this.searchRewardProgram = item.rewardProgramName;
      this.$refs['update-modal'].show();
    },
    hideModalUpdate() {
      this.$refs['update-modal'].hide();
    },
    updateModel: async function () {
      if (this.searchRewardProgram.trim() === '') {
        this.selectedRewardProgram = null;
      }
      if (this.selectedRewardProgram === null) {
        alert('Vui lòng chọn chương trình thưởng hoa hồng!');
        return;
      }
      let data = {
        id: this.modelUpdate.id,
        date: this.modelUpdate.date
          ? moment(this.modelUpdate.date, 'DD/MM/YYYY').valueOf()
          : null,
        employeeId: this.modelUpdate.employeeId,
        operatorType: this.modelUpdate.operatorType,
        amountType: this.modelUpdate.amountType,
        amount: this.modelUpdate.amount
          ? unMaskPrice(this.modelUpdate.amount)
          : 0,
        description: this.modelUpdate.description,
        rewardProgramId: this.selectedRewardProgram,
      };
      ApiService.setHeader();
      ApiService.put('reward-details', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalUpdate();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    onSelectedRewardProgram(option) {
      this.searchRewardProgram = option.item.name;
      this.selectedRewardProgram = option.item.id;
    },
    onInputChangeRewardProgram(text) {
      if (!text) {
        text = '';
      }
      this.searchRewardProgram = text;

      const filteredData = this.optionRewardProgram[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionRewardProgram = [
        {
          data: filteredData,
        },
      ];
    },
    getListRewardProgram() {
      ApiService.setHeader();
      ApiService.query('rewardProgram/get-all', { params: { type: 2 }}).then(({ data }) => {
        this.optionRewardProgram[0].data = data.data;
        this.filteredOptionRewardProgram = [
          { data: this.optionRewardProgram[0].data },
        ];
        this.optionRewardProgramSearch[0].data = data.data;
        this.filteredOptionRewardProgramSearch = [
          { data: this.optionRewardProgramSearch[0].data },
        ];
      });
    },
    fetchEmployee: async function () {
      this.optionEmployee[0].data = [];
      this.optionEmployeeSearch[0].data = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee[0].data.push(store);
          this.optionEmployeeSearch[0].data.push(store);
        });
        this.filteredOptionEmployee = [{ data: this.optionEmployee[0].data }];
        this.filteredOptionEmployeeSearch = [
          { data: this.optionEmployeeSearch[0].data },
        ];
      });
    },
    onSelectedEmployee(option) {
      this.searchEmployee = '';
      const empDetail = {
        id: v4(),
        date: moment().format('DD/MM/YYYY'),
        employeeId: option.item.id,
        employeeName: option.item.name,
        operatorType: 1,
        amountType: 1,
        amount: 0,
        description: '',
        storeId: null,
      };
      this.listDetailByEmp.push(empDetail);
      this.searchEmployee = '';
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    showDeleteAlertItem: function (item) {
      Swal.fire({
        title: 'Xóa mức cộng trừ khác!',
        text: 'Bạn có chắc muốn xóa mức cộng trừ khác này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.listDetailByEmp = _.reject(this.listDetailByEmp, (x) => {
            return x.id == item.id;
          });
        }
      });
    },
    showDeleteAlertEmp: function (item) {
      Swal.fire({
        title: 'Xóa nhân viên!',
        text: 'Bạn có chắc muốn xóa nhân viên này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.listEmp = _.reject(this.listEmp, (x) => {
            return x.id == item.id;
          });
        }
      });
    },
    saveEmpDetail() {
      if (this.searchRewardProgram.trim() === '') {
        this.selectedRewardProgram = null;
      }
      if (this.selectedRewardProgram === null) {
        alert('Vui lòng chọn chương trình hoa hồng!');
        return;
      }
      this.listDetailByEmp.forEach((item) => {
        let amountUnmask = unMaskPrice(item.amount);
        item.amount = parseInt(amountUnmask);
        item.date = item.date
          ? moment(item.date, 'DD/MM/YYYY').valueOf()
          : null;
      });
      let data = {
        rewardProgramId: this.selectedRewardProgram,
        listDetail: this.listDetailByEmp.map((element) => {
          return { ...element, type: 2 }
        }),
      };
      ApiService.setHeader();
      ApiService.post('reward-details/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.hideModalAdd();
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
            raw: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              date: element['Ngày'] ? element['Ngày'] : '',
              employeeName: element['Tên nhân viên'],
              employeeCode: element['Mã nhân viên'],
              operatorType: element['Toán tử'] === '+' ? 1 : 2,
              amountType: element['Loại'] === '$' ? 1 : 2,
              amount: element['Giá trị'] ? element['Giá trị'] : 0,
              description: element['Mô tả'] ? element['Mô tả'] : '',
              rewardProgramName: element['Chương trình thưởng']
                ? element['Chương trình thưởng']
                : '',
            };
            this.importItems.push(data);
          });
          //validate data
          this.validData();
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalAddByExcel() {
      this.isNew = true;
      this.listDetailByEmp = [];
      this.$refs['add-by-excel-modal'].show();
    },
    hideModalAddByExcel() {
      this.$refs['add-by-excel-modal'].hide();
    },
    validData() {
      let data = {
        listDetail: this.importItems.map(item => ({...item, type: 2})),
      };
      ApiService.setHeader();
      ApiService.post('reward-details/valid-data-import', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.listDetailByExcel.push(element);
            });
          } else {
            this.makeToastFaile(message);
            this.clearFiles();
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFaile(message);
          this.clearFiles();
        });
    },
    onSelectedEmployeeSearch(option) {
      this.searchEmployeeName = option.item.name;
      this.selectedEmpId = option.item.id;
    },
    onInputChangeEmployeeSearch(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployeeName = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployeeSearch[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployeeSearch = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedRewardProgramSearch(option) {
      this.searchRewardProgramName = option.item.name;
      this.selectedRewardProgramId = option.item.id;
    },
    onInputChangeRewardProgramSearch(text) {
      if (!text) {
        text = '';
      }
      this.searchRewardProgramName = text;

      const filteredData = this.optionRewardProgramSearch[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionRewardProgramSearch = [
        {
          data: filteredData,
        },
      ];
    },
    saveEmpDetailByExcel() {
      this.listDetailByExcel.forEach((item) => {
        let amountUnmask = unMaskPrice(item.amount);
        item.amount = parseInt(amountUnmask);
        item.date = item.date
          ? moment(item.date, 'DD/MM/YYYY').valueOf()
          : null;
        item.type = 2;
      });
      let data = {
        listDetail: this.listDetailByExcel,
      };
      ApiService.setHeader();
      ApiService.post('reward-details/by-list', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.hideModalAddByExcel();
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
  },
};
</script>
<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  inset: auto !important;
}
.search-col {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 10px;
}
</style>
